import {
	FETCH_HOTELS_REQUEST,
	FETCH_HOTELS_SUCCESS,
	RESET_HOTEL_ONLY_BOOKING_FLOW_ID,
} from "./hotelOnlyActionCreator";

const initialState = {
	hotels: [],
	isLoading: false,
	metadata: {
		pagination: {
			totalItems: 0,
		},
	},
	bookingFlowId: null,
	facets: null,
};

const hotelOnlyReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_HOTELS_REQUEST:
			return {
				...state,
				isLoading: true,
				error: null,
			};

		case FETCH_HOTELS_SUCCESS: {
			const { data, metadata } = action.res.data;

			return {
				...state,
				isLoading: false,
				hotels: data,
				metadata: {
					pagination: {
						totalItems: metadata.pagination.totalItems,
					},
				},
				facets: metadata.facets,
				bookingFlowId: metadata.bookingFlowId,
				error: null,
			};
		}

		case RESET_HOTEL_ONLY_BOOKING_FLOW_ID: {
			return { ...state, bookingFlowId: undefined };
		}
		default:
			return state;
	}
};

export default hotelOnlyReducer;
