import { memo } from "react";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import CustomFormattedMessageContainer from "app/utils/CustomFormattedMessageContainer";
import FloatingButton from "app/pages/.shared/Popover/FloatingButton";
import PaymentModesTooltipContainer from "app/pages/Booking/Payment/PaymentData/PaymentDataModeFirst/PaymentModes/PaymentModesTooltipContainer";
import IconInfo from "app/pages/.shared/static/icons/IconInfo";
import "./PaymentDescription.scss";
import PropTypes from "prop-types";

const PaymentDescription = ({ availablePaymentMethods = [], message }) => {
	return (
		<div className="payment-description">
			<Typography
				variant={TYPOGRAPHY_VARIANTS.LARGE}
				variantMD={TYPOGRAPHY_VARIANTS.XL2}
				isBold
				className="payment-description__content"
			>
				<CustomFormattedMessageContainer
					message={message}
					className="payment-description__terms"
					children={
						<FloatingButton
							position="bottom"
							floatingContent={
								<PaymentModesTooltipContainer
									paymentModes={availablePaymentMethods}
								/>
							}
							referenceComponent={<IconInfo width={15} height={15} />}
						/>
					}
					enableHTMLFormatting={true}
				/>
			</Typography>
		</div>
	);
};
PaymentDescription.propTypes = {
	availablePaymentMethods: PropTypes.array,
	message: PropTypes.string,
};

export default memo(PaymentDescription);
