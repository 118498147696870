import PropTypes from "prop-types";
import { memo, useCallback, useContext, useState } from "react";
import "./ShopSwitcher.scss";
import IconArrowDown from "app/pages/.shared/static/icons/IconArrowDown";
import IconArrowUp from "app/pages/.shared/static/icons/IconArrowUp";
import { replaceShopInUrl } from "app/utils/urlDataExtractor";
import { sendTagOnChangeLanguage } from "app/utils/analytics";
import {
	flip,
	offset,
	safePolygon,
	shift,
	useFloating,
	useHover,
	useInteractions,
} from "@floating-ui/react";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import { getCurrencySymbol } from "app/utils/utils";
import appGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/constants";

const ShopSwitcher = ({ currentShop = "", shops = [] }) => {
	const [isOpen, setIsOpen] = useState(false);

	const [, currentCountryCode] = currentShop.split("-");

	const { resolution } = useContext(appGlobalsContext);

	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const switchLanguage = useCallback(
		code => {
			window.location = replaceShopInUrl(window.location.href, currentShop, code);
			sendTagOnChangeLanguage(code.slice(0, 2));
		},
		[currentShop]
	);

	const { context, x, y, refs, strategy } = useFloating({
		placement: isMobile ? "bottom" : "bottom-end",
		strategy: "absolute",
		open: isOpen,
		onOpenChange: setIsOpen,
		middleware: [offset(10), shift(), flip()],
	});

	const hover = useHover(context, {
		mouseOnly: true,
		handleClose: safePolygon(),
	});

	const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

	const handleClick = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	return (
		shops.length > 1 && (
			<div
				className="shop-switcher"
				data-testid="shop-switcher"
				ref={refs.setReference}
				{...getReferenceProps({})}
				onClick={handleClick}
			>
				<div className="shop-switcher__button">
					<i className={`icon icon--flag-${currentCountryCode}`} />
					{isOpen ? (
						<IconArrowUp height={12} width={12} />
					) : (
						<IconArrowDown height={12} width={12} />
					)}
				</div>
				<Typography
					className="shop-switcher__current-shop"
					variant={TYPOGRAPHY_VARIANTS.SMALL}
					isBold
				>
					{currentShop}
				</Typography>
				{isOpen && (
					<div
						className="shop-switcher__body"
						ref={refs.setFloating}
						{...getFloatingProps({
							style: {
								position: strategy,
								left: x,
								top: y,
							},
						})}
					>
						<ul className="shop-list">
							{shops.map(({ code, currencyCode, label }) => {
								const [, countryCode] = code.split("-");
								const handleLanguageClick = () => {
									switchLanguage(code);
								};

								const symbol = getCurrencySymbol(code, currencyCode);

								return (
									<li
										key={code}
										className="shop-list__item"
										onClick={handleLanguageClick}
									>
										<div className="shop-list__flag">
											<i className={`icon icon--flag-${countryCode}`} />
											<Typography
												variant={TYPOGRAPHY_VARIANTS.LARGE}
												isBold={currentShop === code}
											>
												{label} ({code})
											</Typography>
										</div>
										<div className="shop-list__currency">
											<Typography
												variant={TYPOGRAPHY_VARIANTS.LARGE}
												isBold={currentShop === code}
											>
												{symbol}
											</Typography>
										</div>
									</li>
								);
							})}
						</ul>
					</div>
				)}
			</div>
		)
	);
};

ShopSwitcher.propTypes = {
	currentShop: PropTypes.string,
	shops: PropTypes.array,
};

export default memo(ShopSwitcher);
