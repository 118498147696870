import { FETCH_PARTNER_DATA_SUCCESS } from "app/reducers/partnerActionTypes";

const initialState = {
	code: undefined,
	partnerDataNames: [],
	partnerTokensNames: [],
	shopSwitcherList: [],
	shops: {},
};

export default (brand = initialState, action) => {
	switch (action.type) {
		case FETCH_PARTNER_DATA_SUCCESS: {
			const { shopSwitcherList } = action?.res?.data?.brand || [];
			return {
				...brand,
				shopSwitcherList: shopSwitcherList,
			};
		}
		default:
			return brand;
	}
};
