import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import RelativeLink from "app/pages/.shared/RelativeLink";
import ProgressiveImg from "app/pages/.shared/ProgressiveImg";
import Paragraphs from "app/pages/.shared/Paragraphs";
import { useAnalytics } from "app/utils/analytics/useAnalytics";
import "./OneMerchBanner.scss";
import { RESOLUTION } from "app/constants";
import { stringify } from "qs";
import DecreasingPriceStamp from "app/pages/.shared/DecreasingPriceStamp/DecreasingPriceStamp";
import { isAbsoluteUrl } from "app/utils/urlDataExtractor";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import classNames from "classnames";

const IMG_SIZES = {
	small: { width: 600 },
	medium: { width: 600 },
	large: { width: 350, height: 196 },
	xlarge: { width: 350, height: 196 },
};

const OneMerchBanner = ({ resolution, title = "", merchandising = {}, photoPosition = "left" }) => {
	const { track } = useAnalytics();
	const navigate = useNavigate();
	const location = useLocation();

	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const imageUrl = isMobile ? merchandising.photoSmall?.url : merchandising.photoLarge?.url;
	const thumbnail = isMobile
		? merchandising.photoSmall?.thumbnail
		: merchandising.photoLarge?.thumbnail;
	const buttonLabel = merchandising.displayOptions?.labelCta;
	const to = merchandising.displayOptions?.redirectUrl
		? { pathname: merchandising.displayOptions?.redirectUrl }
		: { pathname: "/merch", search: `?${stringify({ code: merchandising.code })}` };

	const trackRedirectUrl = merchandising.displayOptions?.redirectUrl
		? { pathname: merchandising.displayOptions?.redirectUrl }
		: { pathname: "/merch", search: merchandising.code };

	const merchIntro = merchandising.displayOptions?.marketingDescription;
	const merchandisingStamp = merchandising.displayOptions?.stamp;
	const lowestPrice = merchandising.lowestPrice;
	let merchTitle = merchandising.displayOptions?.smartDP?.merchandisingTitle;
	const merchSubtitle = merchandising.displayOptions?.smartDP?.merchandisingSubtitle;

	if (merchSubtitle) {
		merchTitle = `${merchTitle}, ${merchSubtitle}`;
	}
	const handleCTAClick = useCallback(() => {
		track("one-merch-banner_block_cta_click", {
			ctaLabel: buttonLabel,
			title: title,
			merchtitle: merchTitle,
			actualUrl: location.pathname,
			redirectUrl: trackRedirectUrl,
		});
	}, [buttonLabel, title, merchTitle, location.pathname, trackRedirectUrl]);

	const handleOneMerchClick = useCallback(() => {
		handleCTAClick();

		if (merchandising.displayOptions?.redirectUrl) {
			if (isAbsoluteUrl(merchandising.displayOptions?.redirectUrl)) {
				window.location = merchandising.displayOptions.redirectUrl;
			} else {
				navigate({ pathname: `/${merchandising.displayOptions.redirectUrl}` });
			}
		} else {
			navigate({
				pathname: "/merch",
				search: `?${stringify({ code: merchandising.code })}`,
			});
		}
	}, [merchandising?.displayOptions?.redirectUrl, merchandising?.code]);

	const oneMerchBannerClassNames = classNames("one-merch-banner__wrapper", {
		"one-merch-banner__wrapper--reverse": photoPosition === "right",
	});

	return (
		<div className="one-merch-banner">
			{title && title[0] !== " " && (
				<Typography
					variant={TYPOGRAPHY_VARIANTS.XL3}
					variantMD={TYPOGRAPHY_VARIANTS.XL4}
					isBold
					className="one-merch-banner__title"
					component="h2"
				>
					<Paragraphs paragraphs={title} enableHTMLFormatting />
				</Typography>
			)}
			<div className={oneMerchBannerClassNames}>
				{imageUrl && (
					<div className="one-merch-banner__image" onClick={handleOneMerchClick}>
						<ProgressiveImg
							sizes={IMG_SIZES}
							resolution={resolution}
							src={imageUrl}
							thumbnail={thumbnail}
						/>
						{(merchandisingStamp?.showLowestPrice !== false ||
							merchandisingStamp?.showLabel) && (
							<div
								className={classNames("one-merch-banner__stamp", {
									"one-merch-banner__stamp--left": photoPosition === "right",
								})}
							>
								<DecreasingPriceStamp
									showLowestPrice={merchandisingStamp?.showLowestPrice}
									showLabel={merchandisingStamp?.showLabel}
									label={merchandisingStamp?.label}
									showIcon={merchandisingStamp?.showIcon}
									icon={merchandisingStamp?.icon}
									lowestPrice={lowestPrice}
								/>
							</div>
						)}
					</div>
				)}
				<div className="one-merch-banner__container">
					{merchTitle?.length > 0 && (
						<Typography
							variant={TYPOGRAPHY_VARIANTS.XL}
							isBold
							className="one-merch-banner__merch-title"
							component="h3"
						>
							<Paragraphs paragraphs={merchTitle} enableHTMLFormatting />
						</Typography>
					)}
					<article className="one-merch-banner__merch-intro">
						<Paragraphs paragraphs={merchIntro} enableHTMLFormatting />
					</article>
					{buttonLabel && (
						<RelativeLink
							to={to}
							onClick={handleCTAClick}
							className="one-merch-banner__cta"
							variant="secondary"
							data-testid="reinsurance-cta"
						>
							{buttonLabel}
						</RelativeLink>
					)}
				</div>
			</div>
		</div>
	);
};

OneMerchBanner.propTypes = {
	resolution: PropTypes.string,
	title: PropTypes.string,
	merchandising: PropTypes.object,
	photoPosition: PropTypes.string,
};

export default memo(OneMerchBanner);
