import { memo, useContext } from "react";
import PropTypes from "prop-types";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import SmartDPMarketingHeroImage from "app/pages/SmartDP/SmartDPMarketingHeroImage/SmartDPMarketingHeroImage";
import "./SmartDPHeroCommercial.scss";
import SmartDPHeroCommercialImageContent from "app/pages/SmartDP/SmartDPHeroCommercial/SmartDPHeroCommercialImageContent";

const SmartDPHeroCommercial = ({ websiteTunnels = [], heroCommercialMerch = {} }) => {
	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.MEDIUM || resolution === RESOLUTION.SMALL;

	const MainDesktop =
		heroCommercialMerch?.displayOptions?.heroCommercial?.mainDesktop ||
		heroCommercialMerch?.mainPhotoLarge?.url;

	const MainMobile =
		heroCommercialMerch?.displayOptions?.heroCommercial?.mainMobile ||
		heroCommercialMerch?.mainPhotoSmall?.url;

	const MainDesktopThumbnail =
		heroCommercialMerch?.displayOptions?.heroCommercial?.mainDesktopThumbnail ||
		heroCommercialMerch?.mainPhotoLarge?.thumbnail;
	const MainMobileThumbnail =
		heroCommercialMerch?.displayOptions?.heroCommercial?.mainMobileThumbnail ||
		heroCommercialMerch?.mainPhotoSmall?.thumbnail;
	const backgroundImageUrl = isMobile ? MainMobile : MainDesktop;

	const backgroundImageThumbnail = isMobile ? MainMobileThumbnail : MainDesktopThumbnail;
	return (
		<>
			<div
				className="smartDP-hero-commercial__image-background"
				data-testid="smartDP-hero-commercial-image-background"
			>
				<SmartDPMarketingHeroImage
					imageUrl={backgroundImageUrl}
					imageThumbnail={backgroundImageThumbnail}
					resolution={resolution}
				/>
			</div>
			{!isMobile && (
				<div className="smartDP-hero-commercial__content">
					<SmartDPHeroCommercialImageContent
						websiteTunnels={websiteTunnels}
						heroCommercialMerch={heroCommercialMerch}
					/>
				</div>
			)}
		</>
	);
};
SmartDPHeroCommercial.propTypes = {
	websiteTunnels: PropTypes.array,
	heroCommercialMerch: PropTypes.object,
};
export default memo(SmartDPHeroCommercial);
