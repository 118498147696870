export const convertTimestampToYYYYMMDD = timestamp => {
	if (!timestamp) {
		throw new Error("Missing timestamp value");
	}

	const date = new Date(Number(timestamp));

	if (isNaN(date.getTime())) {
		throw new Error(`Invalid timestamp: ${timestamp}`);
	}

	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");

	return `${year}-${month}-${day}`;
};
