import PropTypes from "prop-types";
import { connect } from "react-redux";
import MembershipBlock from "app/pages/.shared/DynamicBlocks/blocks/Membership/MembershipBlock";
import { isAuthenticated, isIdentified } from "app/pages/Auth/authSelectors";
import { getPartnerAuthLayer } from "app/reducers/partnerSelector";
import { productShape } from "app/utils/propTypes";
import { getMembershipProducts } from "app/pages/Listing/productsSelector";
import { useContext, useEffect } from "react";
import AppGlobalsContext from "app/AppGlobalsContext";
import { bindActionCreators } from "redux";
import { fetchProducts } from "app/pages/Listing/productActionCreators";
import withRouter from "app/utils/hocs/withRouter";

const MembershipBlockContainer = ({ products = [], fetchProducts = () => {}, ...restProps }) => {
	const { shop, productsVisibility, partnerCode } = useContext(AppGlobalsContext);

	useEffect(() => {
		fetchProducts({ productsVisibility, shop, partnerCode });
	}, [productsVisibility, shop, partnerCode]);

	return <MembershipBlock products={products} {...restProps} />;
};

MembershipBlockContainer.propTypes = {
	fetchProducts: PropTypes.func,
	userIsConnected: PropTypes.bool,
	products: PropTypes.arrayOf(productShape),
};

const mapStateToProps = (state, ownProps) => {
	const isMerchPage = ownProps.location?.pathname?.includes("/merch");
	const authLayerTexts = getPartnerAuthLayer(state);
	const userIsConnected = isAuthenticated(state);
	const userIsIdentified = isIdentified(state);

	return {
		products: getMembershipProducts(isMerchPage)(state),
		userIsConnected,
		userIsIdentified,
		protectedPageTexts: authLayerTexts.protectedPage,
		membershipTexts: authLayerTexts.membership,
		advantages: state?.partner?.marketing?.membership?.advantages,
		title:
			userIsConnected || userIsIdentified
				? state?.partner?.marketing?.membership?.connectedTitle
				: state?.partner?.marketing?.membership?.disconnectedTitle,
		cta: state?.partner?.marketing?.membership?.cta,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			fetchProducts,
		},
		dispatch
	);
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(MembershipBlockContainer)
);
