import PropTypes from "prop-types";
import React, { memo } from "react";
import { htmlSanitize } from "app/utils/sanitize";
import parse from "html-react-parser";
import "./CustomFormattedMessage.scss";

const interpolateMessage = (msg, values = {}, enableHTMLFormatting) => {
	let newMessage = msg;
	if (typeof newMessage === "object") {
		newMessage = msg[0];
	}

	if (!newMessage) {
		return newMessage;
	}

	if (enableHTMLFormatting) {
		// Replace placeholders {key} with corresponding values
		return newMessage.replace(/{([^{}]+)}/g, (match, key) => {
			const value = values?.[key];
			return typeof value !== "undefined" ? String(value) : match;
		});
	} else if (values) {
		// Split the message into parts and replace placeholders with values or React elements
		return newMessage
			.split(/({[^}]+})/g)
			.filter(Boolean)
			.map((part, index) => {
				const match = part.match(/{([^{}]+)}/);
				if (match) {
					const value = values[match[1].trim()];
					if (typeof value !== "undefined") {
						return React.isValidElement(value)
							? React.cloneElement(value, { key: index })
							: value;
					}
					return match[0];
				}
				return part;
			});
	}
	// Return the original message if no values are provided
	return newMessage;
};

const CustomFormattedMessage = ({
	message,
	className,
	values = {},
	children,
	enableHTMLFormatting = false,
}) => {
	const renderedMessage = interpolateMessage(message, values, enableHTMLFormatting);

	const renderedMessageComponent = enableHTMLFormatting ? (
		<div className="custom-formatted-message__rendered-message">
			{renderedMessage && parse(htmlSanitize(renderedMessage))}
			{children}
		</div>
	) : (
		<div className="custom-formatted-message__rendered-message">
			{renderedMessage}
			{children}
		</div>
	);
	return className ? (
		<div className={className}>{renderedMessageComponent}</div>
	) : (
		<>{renderedMessageComponent}</>
	);
};

CustomFormattedMessage.propTypes = {
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
	values: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.any,
	enableHTMLFormatting: PropTypes.bool,
};

export default memo(CustomFormattedMessage);
