import { memo, useLayoutEffect, useRef, useState } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { messagePropType } from "app/utils/propTypes";
import "./LineClampViewMore.scss";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const LineClampViewMore = ({
	enableShowMore,
	text,
	lineCount,
	customShowMoreComponent,
	customShowLessComponent,
}) => {
	const [isLineClamped, updateLineClamped] = useState(false);
	const [isViewMore, updateIsViewMore] = useState(false);
	const ref = useRef();

	useLayoutEffect(() => {
		if (ref.current) {
			// @see https://stackoverflow.com/questions/52232026/determine-if-ellipsis-is-being-displayed-when-using-webkit-line-clamp-for-multi/52650163
			updateLineClamped(ref.current.offsetHeight < ref.current.scrollHeight);
		}
	}, [ref, text]);

	const lineClampClass = classNames(
		{
			"line-clamp-view-more--show-all": isViewMore,
		},
		{
			"line-clamp-view-more--mutiline": !isViewMore && lineCount > 0,
		}
	);

	const onButtonClick = value => {
		updateIsViewMore(value);
	};

	return (
		<div className="line-clamp-view-more">
			<span
				className={lineClampClass}
				ref={ref}
				style={{
					WebkitLineClamp: lineCount,
				}}
			>
				{text}
				{isViewMore && (
					<>
						{customShowLessComponent ? (
							React.cloneElement(customShowLessComponent, {
								onClick: () => onButtonClick(false),
								className: `${customShowLessComponent.props.className ||
									""} line-clamp-view-more__reduce`.trim(),
							})
						) : (
							<Typography
								variant={TYPOGRAPHY_VARIANTS.REGULAR}
								onClick={() => onButtonClick(false)}
								className="line-clamp-view-more__reduce"
							>
								<>
									<a data-testid="read-more-button">
										<FormattedMessage id="general.reduce" />
									</a>
								</>
							</Typography>
						)}
					</>
				)}
			</span>
			{enableShowMore && isLineClamped && !isViewMore && (
				<>
					{customShowMoreComponent ? (
						React.cloneElement(customShowMoreComponent, {
							onClick: () => onButtonClick(true),
							className: `${customShowMoreComponent.props.className ||
								""} line-clamp-view-more__view-more`.trim(),
						})
					) : (
						<Typography
							variant={TYPOGRAPHY_VARIANTS.REGULAR}
							component={"span"}
							onClick={() => onButtonClick(true)}
							className="line-clamp-view-more__view-more"
						>
							…
							<a data-testid="read-more-button">
								<FormattedMessage id="general.read.more" />
							</a>
						</Typography>
					)}
				</>
			)}
		</div>
	);
};

LineClampViewMore.propTypes = {
	lineCount: PropTypes.number,
	text: messagePropType,
	enableShowMore: PropTypes.bool,
	customShowMoreComponent: PropTypes.element,
	customShowLessComponent: PropTypes.element,
};

export default memo(LineClampViewMore);
