import axios from "axios";
import { getStore } from "app/configureStore";
import env from "app/utils/env";
import { FETCH_HOTEL_ONLY_FILTERS } from "app/pages/HotelOnly/HotelOnlyActionTypes";
import { BOOK, BOOK_EXTERNAL_PAYMENT } from "app/actionTypes";
import { getCredentials } from "app/utils/auth";

export const FETCH_HOTELS = "FETCH_HOTELS";
export const FETCH_HOTELS_REQUEST = "FETCH_HOTELS_REQUEST";
export const FETCH_HOTELS_SUCCESS = "FETCH_HOTELS_SUCCESS";
export const RESET_HOTEL_ONLY_BOOKING_FLOW_ID = "RESET_HOTEL_ONLY_BOOKING_FLOW_ID";

export const fetchHotels = quotationPayload => {
	const endpoint = env("HOTEL_ONLY_API_URL");

	return {
		type: FETCH_HOTELS,
		promise: axios.post(`${endpoint}/quote`, {
			...quotationPayload,
		}),
	};
};

export const fetchHotelOnlySearchFormAvailability = () => {
	const state = getStore().getState();
	const shop = state.shop;
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");

	return {
		type: FETCH_HOTEL_ONLY_FILTERS,
		promise: axios.get(
			`${endpoint}/${state.productsVisibility}/${shop}/hypnos/hotel_destinations.json`
		),
	};
};

export const hotelOnlyBookExternalPayment = ({ bookingFlowId }) => {
	const url = `${env("HOTEL_ONLY_API_URL")}/bookExternalPayment`;

	return {
		type: BOOK_EXTERNAL_PAYMENT,
		promise: axios.post(
			url,
			{ bookingFlowId },
			{
				headers: {
					"Content-type": "application/json",
				},
			}
		),
	};
};

export const hotelOnlyBook = paymentPayload => {
	const url = `${env("HOTEL_ONLY_API_URL")}/book`;
	const { token } = getCredentials();
	const headers = {
		Authorization: token,
		"Content-type": "application/json",
	};
	return {
		type: BOOK,
		promise: axios.post(url, paymentPayload, { headers }),
	};
};

export const resetHotelOnlyBookingFlowId = () => {
	return {
		type: RESET_HOTEL_ONLY_BOOKING_FLOW_ID,
	};
};
