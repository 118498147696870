import { memo } from "react";
import PropTypes from "prop-types";
import "./ReasonToBook.scss";
import { messagePropType } from "app/utils/propTypes";
import CustomFormattedMessageContainer from "app/utils/CustomFormattedMessageContainer";

const ReasonToBook = ({ title, description, picto }) => {
	return (
		<div className="reason-to-book">
			{picto && (
				<div className="reason-to-book__picto">
					<i
						style={{
							maskImage: `url(${picto}`,
							WebkitMaskImage: `url(${picto}`,
						}}
					/>
				</div>
			)}
			<div>
				{title && (
					<CustomFormattedMessageContainer
						message={title}
						className="reason-to-book__title"
						enableHTMLFormatting={true}
					/>
				)}
				{description && (
					<CustomFormattedMessageContainer
						message={description[0]}
						className="reason-to-book__description"
						enableHTMLFormatting={true}
					/>
				)}
			</div>
		</div>
	);
};

ReasonToBook.propTypes = {
	title: messagePropType,
	description: messagePropType,
	picto: PropTypes.string,
};

export default memo(ReasonToBook);
