import { getStore } from "../configureStore";
import { stringify } from "qs";
import { STRATEGY } from "app/constants";
import Cookies from "js-cookie";
import { OAUTH_PARAMS } from "src/@brand/appConstants";
import env from "app/utils/env";

export function getCredentials(shop) {
	const token = Cookies.get("authToken", { path: `/${shop}` });
	if (token) {
		return { token };
	}
	return false;
}

export function getTokenFromPartner() {
	const shop = getStore()?.getState()?.shop;
	const token = Cookies.get("tokenFromPartner", { path: `/${shop}` });
	if (token) {
		return token;
	}
	return false;
}

export const isOAuthenticated = () => {
	const state = getStore().getState();
	const strategy = state.partner.strategy;
	const shop = state.shop;
	return (
		(Cookies.get("auth", { path: `/${shop}` }) === "auth" && Cookies.get("authToken")) ||
		strategy === STRATEGY.TRANSACTION_FIRST
	);
};

export const decodeToken = token => {
	if (typeof token !== "string") {
		return undefined;
	}

	const toKenPayload = token.split(".");

	if (!toKenPayload[1]) {
		return undefined;
	}

	try {
		const decodedToken = atob(toKenPayload[1]);
		return JSON.parse(decodedToken);
	} catch (err) {
		return undefined;
	}
};

export const getTokenIdFromToken = token => {
	const decodedToken = decodeToken(token);
	return decodedToken ? decodedToken.tokenId : undefined;
};

export const getOauthIndentityProviderUrl = redirectUri => {
	const queryShape = {
		client_id: OAUTH_PARAMS.CLIENT_ID,
		response_type: OAUTH_PARAMS.RESPONSE_TYPE,
		redirect_uri: redirectUri,
		scope: OAUTH_PARAMS.SCOPE,
		isExternal: OAUTH_PARAMS.IS_EXTERNAL,
	};

	const query = stringify(queryShape);

	return `${env("OAUTH_ENDPOINT")}?${query}`;
};
