import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import SmartDPMarketingHeadline from "app/pages/SmartDP/SmartDPMarketingHeadline/SmartDPMarketingHeadline";
import ChevronRight from "app/pages/.shared/static/icons/ChevronRight";
import { AsyncWebsiteTunnelSwitcher } from "app/routes";
import { FormattedMessage } from "react-intl";
import { stringify } from "qs";
import { isAbsoluteUrl } from "app/utils/urlDataExtractor";
import { useNavigate } from "react-router-dom";

const SmartDPHeroCommercialImageContent = ({ websiteTunnels = [], heroCommercialMerch = {} }) => {
	const navigate = useNavigate();
	const title =
		heroCommercialMerch?.displayOptions?.heroCommercial?.merchandisingTitle ||
		heroCommercialMerch?.displayOptions?.smartDP?.merchandisingTitle;
	const subtitle =
		heroCommercialMerch?.displayOptions?.heroCommercial?.merchandisingSubtitle ||
		heroCommercialMerch?.displayOptions?.smartDP?.merchandisingSubtitle;
	const showCta = heroCommercialMerch?.displayOptions?.heroCommercial?.showCta;
	const labelCta = heroCommercialMerch?.displayOptions?.heroCommercial?.labelCta || (
		<FormattedMessage id="landingPage.footer.redirect.cta.text" />
	);
	const heroTextAlign =
		heroCommercialMerch?.displayOptions?.heroCommercial?.heroTextAlign ||
		heroCommercialMerch?.displayOptions?.heroTextAlign ||
		"center";
	const tag = heroCommercialMerch?.displayOptions?.heroCommercial?.tag;
	const redirectionUrl = heroCommercialMerch?.displayOptions?.heroCommercial?.url || undefined;
	const merchandisingCode = heroCommercialMerch?.code;

	const handleCtaClicked = useCallback(() => {
		if (redirectionUrl) {
			if (isAbsoluteUrl(redirectionUrl)) {
				window.location = redirectionUrl;
			} else {
				navigate({ pathname: `/${redirectionUrl}` });
			}
		} else {
			navigate({ pathname: "/merch", search: `?${stringify({ code: merchandisingCode })}` });
		}
	}, [merchandisingCode, redirectionUrl]);
	const lowestPrice = heroCommercialMerch?.lowestPrice;
	return (
		<>
			<div
				className="smartDP-hero-commercial__image-headline"
				data-testid="smartDP-hero-commercial-image-headline"
			>
				{tag && (
					<Typography
						variant={TYPOGRAPHY_VARIANTS.REGULAR}
						variantMD={TYPOGRAPHY_VARIANTS.XL3}
						isBold
						className="smartDP-hero-commercial__tag"
						data-testid="smartDP-hero-commercial-tag"
						component="div"
					>
						{tag}
					</Typography>
				)}
				<SmartDPMarketingHeadline
					title={title}
					subtitle={subtitle}
					textAlign={heroTextAlign}
					lowestPrice={lowestPrice}
				/>

				{showCta && (
					<Typography
						variant={TYPOGRAPHY_VARIANTS.SMALL}
						variantMD={TYPOGRAPHY_VARIANTS.LARGE}
						isBold
						className="smartDP-hero-commercial__redirect--button"
						data-testid="smartDP-hero-commercial-redirect-button"
						component="div"
					>
						<div className={`button button--secondary`} onClick={handleCtaClicked}>
							{labelCta}
							<ChevronRight />
						</div>
					</Typography>
				)}
			</div>
			<div className="smartDP-hero-commercial__form">
				<AsyncWebsiteTunnelSwitcher websiteTunnels={websiteTunnels} />
			</div>
		</>
	);
};
SmartDPHeroCommercialImageContent.propTypes = {
	websiteTunnels: PropTypes.array,
	heroCommercialMerch: PropTypes.object,
};
export default memo(SmartDPHeroCommercialImageContent);
