import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { hasCustomDynamicHeader } from "app/reducers/brandSelector";
import { getPartnerMarketing } from "app/reducers/partnerSelector";
import { useContext, useEffect, useState } from "react";
import { clearBooking } from "app/pages/Booking/bookingActionCreators";
import PropTypes from "prop-types";
import HomeLayout from "app/pages/HomeLayout/HomeLayout";
import { clearSmartDP } from "app/pages/SmartDP/smartDPActionCreators";
import { getActiveHeroCommercialMerchCode } from "app/pages/Merchandising/merchandisingSelector";
import AppGlobalsContext from "app/AppGlobalsContext";
import { fetchMerchandisingApi } from "app/pages/Merchandising/merchandisingActionCreators";

const HomeLayoutContainer = props => {
	const { shop, partnerCode, productsVisibility } = useContext(AppGlobalsContext);
	const { heroCommercialCode } = props;
	const [heroCommercialMerch, setHeroCommercialMerch] = useState({});
	useEffect(() => {
		props.clearSmartDP();
		props.clearBooking();
	}, []);

	useEffect(() => {
		if (heroCommercialCode) {
			fetchMerchandisingApi({
				code: heroCommercialCode,
				shop,
				productsVisibility,
				partnerCode,
			}).then(response => {
				setHeroCommercialMerch(response?.data?.merchandising);
			});
		}
	}, [heroCommercialCode, shop, productsVisibility, partnerCode]);

	return <HomeLayout {...props} heroCommercialMerch={heroCommercialMerch} />;
};

const mapStateToProps = state => {
	const marketing = getPartnerMarketing(state);
	return {
		isBrandWithCustomHeader: hasCustomDynamicHeader(state),
		isTransparentHeader: marketing?.isTransparentSdpSearchHeader || false,
		heroCommercialCode: getActiveHeroCommercialMerchCode(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			clearBooking,
			clearSmartDP,
		},
		dispatch
	);
};

HomeLayoutContainer.propTypes = {
	clearBooking: PropTypes.func,
	clearSmartDP: PropTypes.func,
	heroCommercialCode: PropTypes.string,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HomeLayoutContainer);
