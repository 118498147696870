import { persistStore } from "redux-persist";
import { createBlacklistFilter, createFilter } from "redux-persist-transform-filter";
import { asyncSessionStorage } from "redux-persist/storages";

const LOCALE_STORAGE_WHITELIST = [
	"auth",
	"email",
	"isCookiePolicyAccepted",
	"isMapPolicyAccepted",
	"profile",
	"coupons",
	"booking",
	"sponsorship",
];
const SESSION_STORAGE_WHITELIST = [
	"ficheProduit",
	"booking",
	"quotation",
	"hotelOnly",
	"navigation",
	"payment",
	"partnerData",
	"merchandising",
	"productsFilterMetaData",
	"partner",
	"smartDP",
	"marketing",
	"searchMyBooking",
];

// id, uri, category, name, resort, photos[0].url pour les info produit dans le tunnel
// le reste pour analytics lors d'un reload par exemple
const saveFicheProduitIdFilter = createFilter("ficheProduit", ["id", "uri"]);

const marketingFilter = createFilter("marketing", [
	"source",
	"utmMedium",
	"utmSource",
	"utmCampaign",
	"utmContent",
	"utmTerm",
]);

const paymentFilter = createFilter("payment", [
	"insurances",
	"timestamp",
	"paymentTypes",
	"paymentModes",
	"dateOfBirthRequired",
	"showFrequentTravellerCardInput",
	"payment4x",
	"paymentSwissbilling",
]);

const profileFilter = createFilter("profile", ["newsletterPreference.shouldDisplayFrequencyModal"]);

const navigationFilter = createFilter("navigation", ["isInApp"]);

const partnerFilter = createFilter("partner", ["strategy"]);

// on persiste le code merch pour le bouton back
const merchandising = createFilter("merchandising", ["current"]);

const coupons = createFilter("coupons", ["showCreditsNotification", "showCreditNotesNotification"]);

const smartDP = createFilter("smartDP", [
	"accommodation",
	"timestamp",
	"code",
	"status",
	"specialOffer",
	"specialOfferInfos",
	"timestamp",
]);

const bookingBlacklistFilter = createBlacklistFilter("booking", [
	"passengers",
	"adults",
	"children",
	"infants",
]);

const bookingWhitelistFilter = createFilter("booking", [
	"passengers",
	"adults",
	"children",
	"infants",
]);

const sponsorshipWhitelistFilter = createFilter("sponsorship", ["canInviteByEmailAt"]);

export const STORAGE_KEY_PREFIX = "reduxPersist";

export const initPersistor = (store, options, cb) => {
	const { shop } = options;

	persistStore(
		store,
		{
			whitelist: LOCALE_STORAGE_WHITELIST,
			transforms: [
				profileFilter,
				coupons,
				bookingWhitelistFilter,
				sponsorshipWhitelistFilter,
			],
			keyPrefix: `${STORAGE_KEY_PREFIX}:${shop}:`,
		},
		() => {
			persistStore(
				store,
				{
					whitelist: SESSION_STORAGE_WHITELIST,
					keyPrefix: `${STORAGE_KEY_PREFIX}:${shop}:`,
					storage: asyncSessionStorage,
					transforms: [
						saveFicheProduitIdFilter,
						paymentFilter,
						partnerFilter,
						merchandising,
						navigationFilter,
						smartDP,
						marketingFilter,
						bookingBlacklistFilter,
					],
				},
				cb
			);
		}
	);
};
