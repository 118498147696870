import CustomFormattedMessage from "app/utils/CustomFormattedMessage";
import { connect } from "react-redux";
import {
	getAvailablePaymentTerms,
	getPartnerDepositPercentage,
} from "app/reducers/partnerSelector";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

const CustomFormattedMessageContainer = props => {
	const intl = useIntl();

	const { onlinePercentage, availablePaymentTerms = [], lowestPrice, ...restProps } = props;

	const values = {
		depositPercentage: onlinePercentage
			? `<span class="custom-formatted-message__value">${intl.formatMessage(
					{ id: "payment.terms.description.payment.percentage" },
					{ percentage: onlinePercentage }
			  )}</span>`
			: "",
		paymentTerms: availablePaymentTerms
			.filter(term => term !== 1)
			.map((term, index) => {
				const linkLabel =
					index !== 0
						? intl.formatMessage({
								id: "payment.terms.description.link.label",
						  })
						: "";
				return `
	                      ${index !== 0 ? `${linkLabel} ` : ""}
	                      <span class="custom-formatted-message__value">${term}x</span>
	                  `;
			})
			.join(""),
		callPrice: lowestPrice ? lowestPrice : "",
	};

	return <CustomFormattedMessage {...restProps} values={values} />;
};

CustomFormattedMessageContainer.propTypes = {
	onlinePercentage: PropTypes.number,
	lowestPrice: PropTypes.number,
	availablePaymentTerms: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
	return {
		onlinePercentage: getPartnerDepositPercentage(state),
		availablePaymentTerms: getAvailablePaymentTerms(state),
		lowestPrice: ownProps?.lowestPrice || state?.merchandising?.current?.lowestPrice,
	};
};

export default connect(mapStateToProps)(CustomFormattedMessageContainer);
