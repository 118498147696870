import { memo, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import CurrentMerchCollectionItem from "app/pages/.shared/CurrentMerchCollection/CurrentMerchCollectionItem/CurrentMerchCollectionItem";
import { FormattedMessage } from "react-intl";
import Separator from "app/pages/.shared/Separator";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import Swiper from "react-id-swiper";
import AppGlobalsContext from "app/AppGlobalsContext";
import "./CurrentMerchCollection.scss";
import { useAnalytics } from "app/utils/analytics/useAnalytics";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const SHOW_SLIDES_NUMBER = {
	[RESOLUTION.MEDIUM]: 3.3,
	[RESOLUTION.SMALL]: 1.4,
};

const CurrentMerchCollection = ({ merchandisingList = [] }) => {
	const { resolution } = useContext(AppGlobalsContext);
	const { track } = useAnalytics();
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const swiperParams = {
		shouldSwiperUpdate: true,
		slidesPerView: SHOW_SLIDES_NUMBER[resolution],
		spaceBetween: 24,
		centeredSlides: isMobile && merchandisingList.length === 1,
	};

	const handleMerchClick = useCallback(
		({ merchandisingCode, position, indexCarousel }) => {
			track("merchandising_click", {
				code: merchandisingCode,
				merchCode: merchandisingCode,
				position: String(position),
				indexCarousel: String(indexCarousel),
				merchandisings: merchandisingList.map(merch => {
					return {
						code: merch.code,
						position: merch.position,
						label: merch.label,
						headerIndex: merch.headerIndex,
						startAt: merch.startAt,
						endAt: merch.endAt,
					};
				}),
			});
		},
		[merchandisingList]
	);

	return (
		<div className="current-merch-collection">
			<Typography
				variant={TYPOGRAPHY_VARIANTS.XL3}
				variantMD={TYPOGRAPHY_VARIANTS.XL4}
				isBold
				className="current-merch-collection__title"
				component="h2"
			>
				<Separator design="middle" />
				<FormattedMessage id="current.merch.collection.title" />
			</Typography>
			{isMobile ? (
				<Swiper {...swiperParams}>
					{merchandisingList.map(merchandising => {
						return (
							<div
								className="current-merch-collection__item"
								key={merchandising.code}
							>
								<CurrentMerchCollectionItem
									onClick={handleMerchClick}
									lowestPrice={merchandising.lowestPrice}
									position={merchandising.position}
									indexCarousel={merchandising.headerIndex}
									merchandisingCode={merchandising.code}
									picture={merchandising.photoSmall}
									resolution={resolution}
									merchandisingStamp={merchandising.displayOptions?.stamp}
									label={merchandising.label}
									merchandisingTitle={
										merchandising.displayOptions?.smartDP?.merchandisingTitle
									}
									merchandisingSubtitle={
										merchandising.displayOptions?.smartDP?.merchandisingSubtitle
									}
									introduction={merchandising.displayOptions?.introduction}
								/>
							</div>
						);
					})}
				</Swiper>
			) : (
				<div className="current-merch-collection__list">
					{merchandisingList.map(merchandising => {
						return (
							<div
								className="current-merch-collection__item"
								key={merchandising.code}
							>
								<CurrentMerchCollectionItem
									onClick={handleMerchClick}
									lowestPrice={merchandising.lowestPrice}
									position={merchandising.position}
									indexCarousel={merchandising.headerIndex}
									merchandisingCode={merchandising.code}
									picture={merchandising.photoLarge}
									resolution={resolution}
									label={merchandising.label}
									merchandisingStamp={merchandising.displayOptions?.stamp}
									merchandisingTitle={
										merchandising.displayOptions?.smartDP?.merchandisingTitle
									}
									merchandisingSubtitle={
										merchandising.displayOptions?.smartDP?.merchandisingSubtitle
									}
									introduction={merchandising.displayOptions?.introduction}
								/>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

CurrentMerchCollection.propTypes = {
	merchandisingList: PropTypes.arrayOf(PropTypes.object),
};

export default memo(CurrentMerchCollection);
