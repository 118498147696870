import { memo, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./HomeLayout.scss";
import { sendTagOnSDPSearchPage } from "app/utils/analytics";
import { DynamicBlocksRendererContainer } from "app/pages/.shared/DynamicBlocks";
import { PushNotificationContainer } from "app/pages/.shared/PushNotification/PushNotificationContainer";
import SmartDPMarketingHeroImageContainer from "app/pages/SmartDP/SmartDPMarketingHeroImage/SmartDPMarketingHeroImageContainer";
import SmartDPMarketingHeadlineContainer from "app/pages/SmartDP/SmartDPMarketingHeadline/SmartDPMarketingHeadlineContainer";
import HeaderContainer from "app/pages/Header/HeaderContainer";
import classNames from "classnames";
import SmartDPSearchHeadMetadata from "app/pages/SmartDP/Search/SmartDPSearchHeadMetadata";
import { AsyncWebsiteTunnelSwitcher } from "app/routes";
import { isEmpty } from "lodash";
import SmartDPHeroCommercialImageContent from "app/pages/SmartDP/SmartDPHeroCommercial/SmartDPHeroCommercialImageContent";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import SmartDPHeroCommercial from "app/pages/SmartDP/SmartDPHeroCommercial/SmartDPHeroCommercial";
import Loader from "app/pages/.shared/Loader";

const HomeLayout = ({
	websiteTunnels = [],
	homeBlocks = [],
	isBrandWithCustomHeader,
	heroCommercialMerch = {},
	isTransparentHeader,
	heroCommercialCode,
}) => {
	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.MEDIUM || resolution === RESOLUTION.SMALL;
	const smartDPSearchRef = useRef(null);

	useEffect(() => {
		sendTagOnSDPSearchPage();
	}, []);

	const containerClass = classNames({
		"home-layout__container": true,
		"home-layout__container--custom-header": isBrandWithCustomHeader || !isTransparentHeader,
	});
	const homeLayoutImageClass = classNames("home-layout__image", {
		"home-layout__image--commercial": !isEmpty(heroCommercialMerch) && isMobile,
	});

	const displayLoading = heroCommercialCode && isEmpty(heroCommercialMerch);
	let heroCommercialContent;
	if (displayLoading) {
		heroCommercialContent = (
			<div className="home-layout__loader">
				<Loader />
			</div>
		);
	} else {
		heroCommercialContent = (
			<SmartDPHeroCommercial
				websiteTunnels={websiteTunnels}
				heroCommercialMerch={heroCommercialMerch}
			/>
		);
	}
	return (
		<div ref={smartDPSearchRef} className="home-layout">
			<SmartDPSearchHeadMetadata />
			<HeaderContainer />
			<div className={containerClass}>
				<div className={homeLayoutImageClass}>
					{heroCommercialCode ? (
						heroCommercialContent
					) : (
						<>
							<div className="home-layout__image-background">
								<SmartDPMarketingHeroImageContainer />
							</div>
							<div className="home-layout__image-headline">
								<SmartDPMarketingHeadlineContainer />
								<div className="home-layout__form">
									<AsyncWebsiteTunnelSwitcher websiteTunnels={websiteTunnels} />
								</div>
							</div>
						</>
					)}
				</div>
				<div className="home-layout__content">
					<div className="home-layout__dynamic-blocks">
						{!isEmpty(heroCommercialMerch) && isMobile && (
							<SmartDPHeroCommercialImageContent
								websiteTunnels={websiteTunnels}
								heroCommercialMerch={heroCommercialMerch}
							/>
						)}
						<DynamicBlocksRendererContainer blocks={homeBlocks} />
					</div>
				</div>
			</div>
			<PushNotificationContainer />
		</div>
	);
};

HomeLayout.propTypes = {
	websiteTunnels: PropTypes.array,
	homeBlocks: PropTypes.array,
	heroCommercialCode: PropTypes.string,
	isBrandWithCustomHeader: PropTypes.bool,
	heroCommercialMerch: PropTypes.object,
	isTransparentHeader: PropTypes.bool,
};

export default memo(HomeLayout);
