import { memo, useContext } from "react";
import PropTypes from "prop-types";
import appGlobalsContext from "app/AppGlobalsContext";

/**
 * Use to customise the flag of the country of the lib react-phone-number-input
 * @see flagComponent of https://catamphetamine.gitlab.io/react-phone-number-input/docs/index.html#phoneinputwithcountry
 */
const PhoneFlag = ({ country, countryName, flagUrl }) => {
	const { publicPath = "" } = useContext(appGlobalsContext);

	let url = flagUrl.replace("{XX}", country);
	if (country === "SA") {
		url = `${publicPath}/icons/flags/SA.svg`;
	}
	return <img height={14} width={21} src={url} alt={countryName} />;
};

PhoneFlag.propTypes = {
	country: PropTypes.string,
	countryName: PropTypes.string,
	flagUrl: PropTypes.string,
};

export default memo(PhoneFlag);
